import React from "react";
import './style.css';
import {Button, Card, CardDeck, Container} from "react-bootstrap";
import LoginButton from "../../components/LoginButton";
import OrderPartsCard from "../../components/OrderPartsCard";
import ConnectCard from "../../components/ConnectCard";

type Props = {};
type State = {};

class Home extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
    }


    render() {
        return (
                <div className="card">
                        <ConnectCard />
                </div>
        );
    }
}

export default Home;
