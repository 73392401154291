import React from 'react';
import './style.css';
import {Button, Card, Container, Form, Spinner } from "react-bootstrap";
import BootstrapLoadingButton from "../../../components/ButtonLoadingState";

type Props = {};
type State = {};

class OrderParts extends React.Component<Props, State> {
    state = {
        sku: "",
        quantity: ""
    } as State; // Initial State


    render() {
        return (
            <Container>
                <Form>
                    <Card>

                    <br/>
                    <br/>
                    <br/>

                    <Form.Group controlId="formOrderParts">
                        <Form.Label>SKU Number </Form.Label>
                        <Form.Control size="lg" type="text" placeholder="Enter SKU Number" />
                    </Form.Group>
                    <br/>

                    <Form.Group controlId="formQuantity">
                        <Form.Label>Quantity </Form.Label>
                        <Form.Control size="lg" type="text"  placeholder="Enter Quantity"/>

                        <br/>
                        <br/>

                    </Form.Group>

                    </Card>

                    <br/>
                    <br/>

                    <Form.Text className="text-muted">
                    Connect to blockchain system ...
                </Form.Text>

                    <br/>
                    <br/>

                    <Button
                        variant="primary"
                        type="submit"
                        size="lg"
                        active
                        href="selectSupplier"
                    >
                        Submit
                    </Button>
                    {/*<Spinner animation="border" role="status">*/}
                    {/*    <span className="sr-only">Loading...</span>*/}
                    {/*</Spinner>*/}
                    {/*<BootstrapLoadingButton />*/}
                </Form>
            </Container>
        );
    }
}

export default OrderParts;
