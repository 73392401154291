import React from 'react';
import './style.css';
import { useAuth0 } from "@auth0/auth0-react";


function About() {
    return (
        <div>
            <h1>About Team PHDDI</h1>
        </div>
    );
}

export default About;
