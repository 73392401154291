import React from 'react';
import { BrowserRouter as Router, Link, Route, Switch } from "react-router-dom";
import Topbar from "./ui/components/Topbar";
import TopBanner from "./ui/components/TopBanner";
import FooterBar from "./ui/components/FooterBar";
import {Card, Container} from 'react-bootstrap';
import About from "./ui/pages/About";
import Home from "./ui/pages/Home";
import OrderParts from "./ui/pages/Home/orderParts";
import SelectSupplier from "./ui/pages/Home/selectSupplier";
import LoginButton from "./ui/components/LoginButton";
import { useAuth0 } from "@auth0/auth0-react";
import InstructionsCardLeft from "./ui/components/OrderPartsCard";


type Props = {};
type State = {};

class App extends React.Component<Props, State> {

    constructor(props: Props) {super(props);}

    render() {
        return (

            <div>
                <Container>
                <Card.Header>
                <Router>
                    <Switch>
                        <Route exact path="/about"><About/></Route>
                        <Route exact path="/home"><Home/></Route>
                        <Route exact path="/orderParts"><OrderParts/></Route>
                        <Route exact path="/selectSupplier"><SelectSupplier/></Route>
                    </Switch>
                </Router>
            </Card.Header>
                </Container>
                <footer className="AppFooter">
                    <FooterBar />
                </footer>
            </div>
        );
    }
}

export default App;
