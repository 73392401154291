import React from 'react';
import './style.css';
import {Button, Card, Container, Nav, Navbar} from "react-bootstrap";
import LoginButton from "../LoginButton";
import action from "../../assets/action.png";
import Image from "react-bootstrap/Image";
import LogoutButton from "../LogoutButton";

class ConnectCard extends React.Component {
    render() {
        return (
            <div>
                <Card border="info" style={{ width: '24rem', height: '36rem' }}>
                    <Card.Header>
                        <h3>Connect ...<Image src={action} id="action"/></h3>
                    </Card.Header>
                    <Card.Body>
                        <Card.Text>
                            <p className="ConnectCardText">
                            Click the Login Button
                                <br/>
                            to connect with the
                                <br/>
                            OAuth 2.0 server.
                            </p>
                            <span className="LoginButton">
                                <LoginButton />
                                <LogoutButton />
                            </span>
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>
        );
    }
}

export default ConnectCard;
