import React from 'react';
import './style.css';
import LogoutButton from "../LogoutButton";
import blockchain_logo from "../../assets/blockchain.png";
import {Card, Container, Nav, Navbar} from "react-bootstrap";
import Image from 'react-bootstrap/Image'

class TopBanner extends React.Component {
    render() {
        return (
            <a href="/home">
                <Card bg="dark" border="info" style={{ height: '12rem' }} >
                    <Card.Header>
                    <h1><Image
                        src={blockchain_logo}
                        id="TopBannerLogo"
                        />
                        Sawtooth Automotive Solution
                    </h1>
                    </Card.Header>
                </Card>
            </a>
        );
    }
}

export default TopBanner;
