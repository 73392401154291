import React from 'react';
import './style.css';
import {Card, Col, Container, Nav, Navbar, Row} from "react-bootstrap";
import Team_PHDDI_logo from "../../assets/logo_only_team_phddi.png";
import Image from "react-bootstrap/Image";

class FooterBar extends React.Component {
    render() {
        return (
            <footer>
                    <Card bg="dark" border="info" style={{ height: '12rem' }}>
                        <Card.Body>
                            <Card.Text>
                                <Row>
                                    <Col >
                                        <span className="FooterTitle">
                                            <Card.Title>
                                                <Image src={Team_PHDDI_logo} id="Team_PHDDI_logo"/>
                                                Team PHDDI
                                            </Card.Title>
                                            <br/>
                                            <h6>
                                                <a href="https://www.linkedin.com/in/alexander-zhovnuvaty-88004026/">
                                                Mr Alexander Zhovnuvaty
                                                </a>
                                                <br/>
                                                <br/>
                                                <a href="https://www.linkedin.com/in/kentglau/">
                                                Dr Kent Lau
                                                </a>
                                            </h6>
                                        </span>
                                    </Col>
                                    <Col id="lastUpdateTimeContainer">
                                        <div className="FooterTitle">
                                            <Card.Title>
                                                <h6>
                                                    <a href="http://hyperledger.org">Hyperledger</a>
                                                </h6>
                                            </Card.Title>
                                            <br/>
                                            <h6>
                                                <a href="https://wiki.hyperledger.org/display/HIRC/HYPERHACK+2021">HyperHack 2021</a>
                                            </h6>
                                            <br/>
                                            <h6>
                                                <div>Icons from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
                                            </h6>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
            </footer>
        );
    }
}

export default FooterBar;
