import React from 'react';
import './style.css';
import {Button, Col, Container, Form, Row, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import ToggleRadioButtons from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';
import ConfirmAlert from "../../../components/Alert";
import AlertDismissible from "../../../components/AlertDismissible";

function SelectSupplier() {
    return (
        <div>
            <h2>Proceed with order ...</h2>
            <br/>
            <br/>
            <ul>
                <li>
                    <h3>
                        SKU# 1111
                    </h3>
                </li>
                <br/>
                <li>
                    <h3>
                        Quantity = 100
                    </h3>
                </li>
                <br/>
                <br/>
                <br/>
                <Container>
                    <h2>Approved Suppliers List</h2>
                </Container>
                <br/>
                <br/>
                <br/>
                <fieldset>
                    <Form.Group>

                        <Col lg={15}>
                            <Form.Check
                                type="radio"
                                label="SupplierA"
                                name="formHorizontalRadios"
                                id="formHorizontalRadios1"
                            />
                            <Form.Check
                                type="radio"
                                label="SupplierB"
                                name="formHorizontalRadios"
                                id="formHorizontalRadios2"
                            />
                            <Form.Check
                                type="radio"
                                label="SupplierC"
                                name="formHorizontalRadios"
                                id="formHorizontalRadios3"
                            />
                        </Col>
                    </Form.Group>
                </fieldset>
            <br/>
            <br/>
            </ul>
            <Button href="/Home">
                Submit and return Home
            </Button>
        </div>
    );
}

export default SelectSupplier;
