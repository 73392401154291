import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";
import 'bootstrap/dist/css/bootstrap.min.css';
import FooterBar from './ui/components/FooterBar';
import TopBanner from "./ui/components/TopBanner";
import { useAuth0 } from "@auth0/auth0-react";





ReactDOM.render(
    <Auth0Provider
        // domain="dev-f284036u.us.auth0.com"
        // clientId="5hKFdXzfxEltfU2Uwbn7V1cenB6P0Tvx"
        // redirectUri="https://team-phddi-hyperhack2021-frontend-react.onrender.com/Home"

        //dev
        domain="dev-f284036u.us.auth0.com"
        clientId="UeVQdLeHXNRqNKWwruvu56QPuu6URUfm"
        redirectUri="http://localhost:3000/Home"
    >

        <TopBanner />
        <App />

    </Auth0Provider>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
